import { DumpI } from "_interfaces/dump.interfaces";
import CInput from "components/input";
import ValidationError from "components/validation/error";
import { fileTypes, imageTypes, layerTypes, operationTypes, styleTypes } from "data/dump";
import useCreatorForm from "hooks/creator/useCreatorForm";
import useUpdateDumpForm from "hooks/dump/useUpdateProduct";
import useThemeForm from "hooks/theme/useThemeForm";
import { useEffect, useState } from "react";
import { Button, Modal, Select } from "react-daisyui";
import { IoClose } from "react-icons/io5";
import { useGetThemeQuery, useGetCreatorQuery } from "services/modules/dump";

const UpdateDump = ({
  open,
  handleClose,
  data,
}: {
  open: boolean;
  handleClose: (refetch?: boolean) => void;
  data?: DumpI;
}) => {
  const { handleUpdate, errors, register, reset, updateState } = useUpdateDumpForm(data?.id || "");

  
  const {
    data: dataTheme,
    isLoading: loadingTheme,
  } = useGetThemeQuery(undefined);
  const { data: dataCreator, isLoading: loadingCreator } = useGetCreatorQuery(undefined);


  const [selectedTheme, setSelectedTheme] = useState<string | undefined>(data?.themeId);

  useEffect(() => {
    if (data?.themeId) {
      console.log('Theme ID dari parameter edit:', data.themeId);
      setSelectedTheme(data.themeId.toString());
    }
  }, [data]);

  
  useEffect(() => {
    if (updateState.isSuccess) {
      handleClose(true);
    }
  }, [updateState.isSuccess]);

  useEffect(() => {
    if(data) {
      reset({...data, creator: data.newCreatorId.toString()});
    }
  }, [data]);

  return (
    <Modal
      className="bg-white w-1/3 max-w-[900px]"
      open={open}
    >
      <Modal.Header className="flex flex-row justify-between">
        Update Product
        <IoClose onClick={() => handleClose()} />
      </Modal.Header>
      <Modal.Body className="overflow-scroll">
        <form
          className="p-2 flex flex-col gap-4"
          onSubmit={handleUpdate}
        >
          <div className="grid grid-cols-2 gap-4">
            <div className="col-span-2 flex items-center justify-center">
              <img
              aria-label="image"
                src={data?.productUrl}
                className="w-[150px]"
              />
            </div>
            <div className="flex flex-col gap-2">
              <label
                className="font-semibold text-[#262626]"
              >
                Name
              </label>
              <CInput
                {...register("title")}
                type="text"
              />
              <ValidationError error={errors.title} />
            </div>
            <div className="flex flex-col gap-2">
              <label
                className="font-semibold text-[#262626]"
              >
                Creator
              </label>
              {dataCreator && (
                <Select {...register(`creator`)}>
                  {dataCreator?.data.map((creator, j) => (
                    <option
                      key={j}
                      value={creator.id.toString()}
                    >
                      {creator.name}
                    </option>
                  ))}
                </Select>
              )}
            </div>
            <div className="flex flex-col gap-2">
              <label
                className="font-semibold text-[#262626]"
              >
                Operation Type
              </label>
              <Select {...register('operatorType')}>
                {operationTypes?.map((operation, j) => (
                  <option
                    key={j}
                    value={operation.value}
                  >
                    {operation.label}
                  </option>
                ))}
              </Select>
            </div>
            <div className="flex flex-col gap-2">
              <label
                className="font-semibold text-[#262626]"
              >
                File Type
              </label>
              <Select {...register(`fileType`)}>
                {fileTypes.map((creator, j) => (
                  <option
                    key={j}
                    value={creator.value}
                  >
                    {creator.label}
                  </option>
                ))}
              </Select>
            </div>
            <div className="flex flex-col gap-2">
              <label
                className="font-semibold text-[#262626]"
              >
                Image Type
              </label>
              <Select {...register(`imageType`)}>
                {imageTypes.map((image, j) => (
                  <option
                    key={j}
                    value={image.value}
                  >
                    {image.label}
                  </option>
                ))}
              </Select>
            </div>
            <div className="flex flex-col gap-2">
              <label
                className="font-semibold text-[#262626]"
              >
                Style
              </label>
              <Select {...register(`style`)}>
                {styleTypes.map((creator, j) => (
                  <option
                    key={j}
                    value={creator.value}
                  >
                    {creator.label}
                  </option>
                ))}
              </Select>
            </div>
            <div className="flex flex-col gap-2">
              <label
                className="font-semibold text-[#262626]"
              >
                Layer
              </label>
              <Select {...register(`layer`)}>
                {layerTypes.map((creator, j) => (
                  <option
                    key={j}
                    value={creator.value}
                  >
                    {creator.label}
                  </option>
                ))}
              </Select>
            </div>
            <div className="flex flex-col gap-2">
              <label
                className="font-semibold text-[#262626]"
              >
                Theme
              </label>
              {dataTheme && (
                <Select {...register(`theme`)} value={selectedTheme} onChange={(e) => setSelectedTheme(e.target.value)}>
                  {dataTheme?.data.map((theme, j) => (
                    <option key={j} value={theme.id}>
                      {theme.name}
                    </option>
                  ))}
                </Select>
              
              )}
            </div>
          </div>
          <div className="w-full flex flex-row justify-end gap-4 col-span-5 mt-10">
            <Button
              type="reset"
              className="border-seeds text-seeds"
              onClick={() => handleClose()}
              loading={updateState.isLoading}
              disabled={updateState.isLoading}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className="bg-san-juan text-white hover:bg-san-juan/90"
              loading={updateState.isLoading}
              disabled={updateState.isLoading}
            >
              Save
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateDump;

