import {
  CreateDumpPayloadI,
  DumpPayload,
  GetDumpsResI,
  GetThemeResI,
} from "_interfaces/dump.interfaces";
import { PagingDTO } from "_interfaces/pagination.interface";
import { Api } from "services/api";

export const dumpApi = Api.injectEndpoints({
  endpoints: (build) => ({
    getDumps: build.query<GetDumpsResI, PagingDTO>({
      query: (params) => {
        return {
          url: "/crafter/admin/dump",
          params,
        };
      },
      keepUnusedDataFor: 0,
    }),
    createDumps: build.mutation<void, CreateDumpPayloadI>({
      query(body) {
        return {
          url: `/crafter/admin/dump/bulk`,
          method: "POST",
          body,
        };
      },
    }),
    updateDump: build.mutation<void, { body: DumpPayload; id: string }>({
      query({ body, id }) {
        return {
          url: `/crafter/admin/dump/${id}`,
          method: "PUT",
          body,
        };
      },
    }),
    deleteDumps: build.mutation<void, string>({
      query(id) {
        return {
          url: `/crafter/admin/dump/${id}`,
          method: "DELETE",
        };
      },
    }),
    createTheme: build.mutation<void, { name: string }>({
      query(body) {
        return {
          url: `/crafter/admin/dump/theme`,
          method: "POST",
          body,
        };
      },
    }),
    getTheme: build.query<GetThemeResI, undefined>({
      query: () => ({ url: "/crafter/dump/theme" }),
      keepUnusedDataFor: 0,
    }),
    deleteTheme: build.mutation<void, string>({
      query: (id) => ({
        url: `/crafter/admin/dump/theme/${id}`,
        method: "DELETE",
      }),
    }),
    createCreator: build.mutation<void, { name: string }>({
      query(body) {
        return {
          url: `/crafter/admin/dump/creator`,
          method: "POST",
          body,
        };
      },
    }),
    getCreator: build.query<GetThemeResI, undefined>({
      query: () => ({ url: "/crafter/dump/creator" }),
      keepUnusedDataFor: 0,
    }),
    deleteCreator: build.mutation<void, string>({
      query: (id) => ({
        url: `/crafter/admin/dump/creator/${id}`,
        method: "DELETE",
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetDumpsQuery,
  useCreateDumpsMutation,
  useDeleteDumpsMutation,
  useUpdateDumpMutation,
  useCreateThemeMutation,
  useGetThemeQuery,
  useDeleteThemeMutation,
  useCreateCreatorMutation,
  useDeleteCreatorMutation,
  useGetCreatorQuery,
} = dumpApi;

