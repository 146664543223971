import {
  CreatorType,
  DumpFormI,
  FileType,
  ImageType,
  LayerType,
  OperationType,
  StyleType,
  ThemeType,
} from "_interfaces/dump.interfaces";
import ContentContainer from "components/container";
import {
  creatorTypes,
  fileTypes,
  imageTypes,
  layerTypes,
  operationTypes,
  styleTypes,
  themeTypes,
} from "data/dump";
import useCreateDumpForm from "hooks/dump/useCreateProduct";
import { useState } from "react";
import { Button, Select } from "react-daisyui";
import { FileUploader } from "react-drag-drop-files";
import { useFieldArray } from "react-hook-form";
import { MdDelete, MdUpload } from "react-icons/md";
import { useGetCreatorQuery, useGetThemeQuery } from "services/modules/dump";

export const createDumpRouteName = "create";
const CreateDumPage = () => {
  const filetype = ["JPG", "PNG", "SVG"];
  const {
    handleCreate,
    register,
    errors,
    setFocus,
    control,
    isLoading,
    watch,
    setValue,
    reset,
  } = useCreateDumpForm();
  const { fields, remove, insert, update } = useFieldArray({
    control,
    name: "data",
  });
  const {
    data: dataTheme,
    isLoading: loadingTheme,
  } = useGetThemeQuery(undefined);
  const { data: dataCreator, isLoading: loadingCreator } = useGetCreatorQuery(undefined);
  const [preset, setPreset] = useState({
    creator: "1",
    operatorType: OperationType.Cut,
    imageType: ImageType.Illustration,
    style: StyleType.Monogram,
    layer: LayerType.Multi,
    theme: "1",
  });

  const fileType = (type: string): FileType => {
    switch (type) {
      case "SVG":
        return FileType.SVG;
      case "PNG":
        return FileType.PNG;
      case "JPG":
        return FileType.JPG;
      default:
        return FileType.SVG;
    }
  };

  const handleUpload = (files: FileList) => {
    if (files.length > 0) {
      let dumps: DumpFormI[] = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        dumps.push({
          title: file.name.split(".")[0],
          productFile: file,
          creator: preset.creator,
          operatorType: preset.operatorType,
          fileType: fileType(file.name.split(".").pop()!.toUpperCase()),
          imageType: preset.imageType,
          style: preset.style,
          layer: preset.layer,
          theme: preset.theme,
        });
      }
      reset({ data: dumps });
    }
  };

  return (
    <ContentContainer>
      <div className="flex items-center justify-between mb-6">
        <h3 className="text-2xl text-[#262626] font-semibold">
          Create Brizy Product
        </h3>
      </div>
      {fields.length > 0 ? (
        <form
          onSubmit={handleCreate}
          className="max-w-full overflow-y-auto"
        >
          <table className="w-full border border-gray-600 rounded-lg">
            <thead className="bg-san-juan">
              <tr className="divide-x divide-[#BDBDBD]">
                <th
                  scope="col"
                  className="p-4 text-center whitespace-nowrap text-sm font-semibold text-san-juan-50"
                >
                  <div className="flex">No</div>
                </th>
                <th
                  scope="col"
                  className="p-4 text-center whitespace-nowrap text-sm font-semibold text-san-juan-50"
                >
                  <div className="flex">Image</div>
                </th>
                <th
                  scope="col"
                  className="p-4 text-center whitespace-nowrap text-sm font-semibold text-san-juan-50"
                >
                  <div className="flex">Title</div>
                </th>
                <th
                  scope="col"
                  className="p-4 text-center whitespace-nowrap text-sm font-semibold text-san-juan-50"
                >
                  <div className="flex">Creator</div>
                </th>
                <th
                  scope="col"
                  className="p-4 text-center whitespace-nowrap text-sm font-semibold text-san-juan-50"
                >
                  <div className="flex">Operation Type</div>
                </th>
                <th
                  scope="col"
                  className="p-4 text-center whitespace-nowrap text-sm font-semibold text-san-juan-50"
                >
                  <div className="flex">File Type</div>
                </th>
                <th
                  scope="col"
                  className="p-4 text-center whitespace-nowrap text-sm font-semibold text-san-juan-50"
                >
                  <div className="flex">Image Type</div>
                </th>
                <th
                  scope="col"
                  className="p-4 text-center whitespace-nowrap text-sm font-semibold text-san-juan-50"
                >
                  <div className="flex">Style</div>
                </th>
                <th
                  scope="col"
                  className="p-4 text-center whitespace-nowrap text-sm font-semibold text-san-juan-50"
                >
                  <div className="flex">Layer</div>
                </th>
                <th
                  scope="col"
                  className="p-4 text-center whitespace-nowrap text-sm font-semibold text-san-juan-50"
                >
                  <div className="flex">Theme</div>
                </th>
                <th
                  scope="col"
                  className="p-4 text-center whitespace-nowrap text-sm font-semibold text-san-juan-50"
                >
                  <div className="flex">Delete</div>
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {fields.map((item, i) => (
                <tr key={i}>
                  <td
                    className={`p-4 text-center whitespace-nowrap text-sm leading-7`}
                  >
                    {i + 1}
                  </td>
                  <td
                    className={`p-4 text-center whitespace-nowrap text-sm leading-7`}
                  >
                    <img
                    aria-label="image"
                      src={URL.createObjectURL(item.productFile)}
                      className="w-[150px]"
                    />
                  </td>
                  <td
                    className={`p-4 text-center whitespace-nowrap text-sm leading-7 w-[300px]`}
                  >
                    <input {...register(`data.${i}.title`)} />
                  </td>
                  <td
                    className={`p-4 text-center whitespace-nowrap text-sm leading-7`}
                  >
                    {dataCreator && (
                      <Select {...register(`data.${i}.creator`)}>
                        {dataCreator?.data.map((creator, j) => (
                          <option
                            key={j}
                            value={creator.id}
                          >
                            {creator.name}
                          </option>
                        ))}
                      </Select>
                    )}
                  </td>
                  <td
                    className={`p-4 text-center whitespace-nowrap text-sm leading-7`}
                  >
                    <Select {...register(`data.${i}.operatorType`)}>
                      {operationTypes.map((operation, j) => (
                        <option
                          key={j}
                          value={operation.value}
                        >
                          {operation.label}
                        </option>
                      ))}
                    </Select>
                  </td>
                  <td
                    className={`p-4 text-center whitespace-nowrap text-sm leading-7`}
                  >
                    <Select {...register(`data.${i}.fileType`)}>
                      {fileTypes.map((creator, j) => (
                        <option
                          key={j}
                          value={creator.value}
                        >
                          {creator.label}
                        </option>
                      ))}
                    </Select>
                  </td>
                  <td
                    className={`p-4 text-center whitespace-nowrap text-sm leading-7`}
                  >
                    <Select {...register(`data.${i}.imageType`)}>
                      {imageTypes.map((image, j) => (
                        <option
                          key={j}
                          value={image.value}
                        >
                          {image.label}
                        </option>
                      ))}
                    </Select>
                  </td>
                  <td
                    className={`p-4 text-center whitespace-nowrap text-sm leading-7`}
                  >
                    <Select {...register(`data.${i}.style`)}>
                      {styleTypes.map((creator, j) => (
                        <option
                          key={j}
                          value={creator.value}
                        >
                          {creator.label}
                        </option>
                      ))}
                    </Select>
                  </td>
                  <td
                    className={`p-4 text-center whitespace-nowrap text-sm leading-7`}
                  >
                    <Select {...register(`data.${i}.layer`)}>
                      {layerTypes.map((creator, j) => (
                        <option
                          key={j}
                          value={creator.value}
                        >
                          {creator.label}
                        </option>
                      ))}
                    </Select>
                  </td>
                  <td
                    className={`p-4 text-center whitespace-nowrap text-sm leading-7`}
                  >
                    {dataTheme && (
                      <Select {...register(`data.${i}.theme`)}>
                        {dataTheme?.data.map((creator, j) => (
                          <option
                            key={j}
                            value={creator.id}
                          >
                            {creator.name}
                          </option>
                        ))}
                      </Select>
                    )}
                  </td>
                  <td
                    className={`p-4 text-center whitespace-nowrap text-sm leading-7`}
                  >
                    <Button
                      variant="outline"
                      color="error"
                      startIcon={<MdDelete />}
                      type="button"
                      onClick={() => {
                        remove(i);
                      }}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="w-full flex justify-end items-end mt-4 sticky left-0">
            <Button
              color="info"
              startIcon={<MdUpload />}
              loading={isLoading}
              disabled={isLoading}
              className="text-white self-end"
            >
              Upload
            </Button>
          </div>
        </form>
      ) : (
        <div className="w-full">
          <div className="grid grid-cols-2 gap-10 mb-6">
            <div className="w-full flex flex-col gap-2">
              <label
                htmlFor="creator"
                className="font-semibold"
              >
                Creator
              </label>
              {dataCreator && (
                <Select
                  onChange={(e) => {
                    setPreset((prev) => ({
                      ...prev,
                      creator: e.target.value,
                    }));
                  }}
                  id="creator"
                  value={preset.creator}
                >
                  {dataCreator?.data.map((creator, j) => (
                    <option
                      key={j}
                      value={creator.id}
                    >
                      {creator.name}
                    </option>
                  ))}
                </Select>
              )}
            </div>
            <div className="w-full flex flex-col gap-2">
              <label
                htmlFor="operation"
                className="font-semibold"
              >
                Operation Type
              </label>
              <Select
                id="operation"
                onChange={(e) => {
                  setPreset((prev) => ({
                    ...prev,
                    operatorType: e.target.value as OperationType,
                  }));
                }}
                value={preset.operatorType}
              >
                {operationTypes.map((operation, j) => (
                  <option
                    key={j}
                    value={operation.value}
                  >
                    {operation.label}
                  </option>
                ))}
              </Select>
            </div>
            <div className="w-full flex flex-col gap-2">
              <label
                htmlFor="imageType"
                className="font-semibold"
              >
                Image Type
              </label>
              <Select
                id="imageType"
                onChange={(e) => {
                  setPreset((prev) => ({
                    ...prev,
                    imageType: e.target.value as ImageType,
                  }));
                }}
                value={preset.imageType}
              >
                {imageTypes.map((image, j) => (
                  <option
                    key={j}
                    value={image.value}
                  >
                    {image.label}
                  </option>
                ))}
              </Select>
            </div>
            <div className="w-full flex flex-col gap-2">
              <label
                htmlFor="styleType"
                className="font-semibold"
              >
                Style Type
              </label>
              <Select
                id="styleType"
                onChange={(e) => {
                  setPreset((prev) => ({
                    ...prev,
                    style: e.target.value as StyleType,
                  }));
                }}
                value={preset.style}
              >
                {styleTypes.map((style, j) => (
                  <option
                    key={j}
                    value={style.value}
                  >
                    {style.label}
                  </option>
                ))}
              </Select>
            </div>
            <div className="w-full flex flex-col gap-2">
              <label
                htmlFor="layer"
                className="font-semibold"
              >
                Layer
              </label>
              <Select
                id="layer"
                onChange={(e) => {
                  setPreset((prev) => ({
                    ...prev,
                    layer: e.target.value as LayerType,
                  }));
                }}
                value={preset.layer}
              >
                {layerTypes.map((layer, j) => (
                  <option
                    key={j}
                    value={layer.value}
                  >
                    {layer.label}
                  </option>
                ))}
              </Select>
            </div>
            <div className="w-full flex flex-col gap-2">
              <label
                htmlFor="theme"
                className="font-semibold"
              >
                Theme
              </label>
              {dataTheme && (
                <Select
                  onChange={(e) => {
                    setPreset((prev) => ({
                      ...prev,
                      theme: e.target.value,
                    }));
                  }}
                  id="theme"
                  value={preset.theme}
                >
                  {dataTheme?.data.map((creator, j) => (
                    <option
                      key={j}
                      value={creator.id}
                    >
                      {creator.name}
                    </option>
                  ))}
                </Select>
              )}
            </div>
          </div>
          <div className="w-full border border-gray-200 rounded-xl h-96 flex items-center justify-center">
            <FileUploader
              multiple
              handleChange={handleUpload}
              name="file"
              types={filetype}
              classes="w-full"
            />
          </div>
        </div>
      )}
    </ContentContainer>
  );
};

export default CreateDumPage;

