import ContentContainer from "components/container";
import SearchInput from "components/search-input";
import { Columns, Table } from "components/table/table";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { userDetailRouteName } from "./detail.page";
import { Button, Select } from "react-daisyui";
import { MdLock, MdLockOpen } from "react-icons/md";
import { ChangeEvent, useEffect, useState } from "react";
import ConfirmationModal from "components/confirmation-modal";
import Pagination from "components/table/pagination";
import { CSVLink } from "react-csv";

import {
  useBlockMutation,
  useGetUsersQuery,
  useUnblockMutation,
} from "services/modules/user";
import { GetDataUserDTO, Order, SortBy, User } from "_interfaces/user.interfaces";

export const userManagementRouteName = "";
const UserPage = () => {
  const navigate = useNavigate();
  const [params, setParams] = useState<GetDataUserDTO>({
    page: 1,
    limit: 500,
    order: Order.DESC,
    sortBy: SortBy.createdat,
  });
  const { data, isLoading, isFetching, refetch } = useGetUsersQuery(params);
  const [block, blockState] = useBlockMutation();
  const [unblock, unblockState] = useUnblockMutation();
  const [isBlockPopupOpen, setIsBlockPopupOpen] = useState<{
    open: boolean;
    action?: "block" | "unblock";
    id?: string;
  }>({ open: false });

  useEffect(() => {
    if (blockState.isSuccess || unblockState.isSuccess) {
      setIsBlockPopupOpen({ open: false });
      refetch();
    }
  }, [blockState.isSuccess, unblockState.isSuccess]);

  const handleBlockUnblock = () => {
    if (isBlockPopupOpen.action === "block") {
      block(isBlockPopupOpen.id!);
    } else {
      unblock(isBlockPopupOpen.id!);
    }
  };

  const handleChangeSort = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value as SortBy;
    setParams(prev => ({ ...prev, sortBy: value }));
  };

  const csvData = data?.data?.map((user, index) => ({
    No: index + 1,
    "Display Name": user.displayName,
    Email: user.email,
    Orders: user.successTransactions,
    Spend: `$${user.spend / 100}`,
    "Coin Spend": user.coinSpend,
    "Registered At": moment(user.createdAt).format("MMM D, YYYY"),
  })) || [];

  const header: Columns<User>[] = [
    {
      fieldId: "index",
      label: "No",
    },
    {
      fieldId: "displayName",
      label: "Display Name",
      render: (data) => (
        <div
          onClick={() => {
            navigate(userDetailRouteName.replace(":id", data.id!));
          }}
          className="underline hover:text-blue-800 cursor-pointer" 
        >
          {data.displayName}
        </div>
      ),
    },
    {
      fieldId: "email",
      label: "Email",
      render: (data) => (
        <div
          onClick={() => {
            navigate(userDetailRouteName.replace(":id", data.id!));
          }}
          className="underline hover:text-blue-800 cursor-pointer"
        >
          {data.email}
        </div>
      ),
    },
    {
      fieldId: "successTransactions",
      label: "Orders",
    },
    {
      fieldId: "spend",
      label: "Spend",
      render: (data) => `$${data.spend / 100}`,
    },
    {
      fieldId: "coinSpend",
      label: "Coin Spend",
    },
    {
      fieldId: "createdAt",
      label: "Registered At",
      render: (data) => moment(data.createdAt).format("MMM D, YYYY"),
    },
    {
      fieldId: "id",
      label: "Action",
      render: (data) => (
        <div className="flex flex-row gap-2">
          {!data.blocked ? (
            <Button
              fullWidth={true}
              variant="outline"
              color="error"
              startIcon={<MdLock />}
              onClick={() => {
                setIsBlockPopupOpen({ open: true, action: "block", id: data.id });
              }}
            >
              Block
            </Button>
          ) : (
            <Button
              fullWidth={true}
              variant="outline"
              color="warning"
              startIcon={<MdLockOpen />}
              onClick={() => {
                setIsBlockPopupOpen({ open: true, action: "unblock", id: data.id });
              }}
            >
              Unblock
            </Button>
          )}
        </div>
      ),
    },
  ];

  return (
    <ContentContainer>
      <div className="flex items-center justify-between mb-6">
        <h3 className="text-2xl text-[#262626] font-semibold">User Management</h3>
        <div className="flex flex-row gap-2 items-center justify-center">
        {csvData.length > 0 ? (
            <CSVLink data={csvData} filename="user_data.csv">
              <Button    
                className="bg-san-juan text-white hover:bg-san-juan/90"
              >
                Download Data
              </Button>
            </CSVLink>
          ) : (
            <Button    
              className="bg-gray-400 text-white cursor-not-allowed"
              disabled
            >
              Download Data
            </Button>
          )}
          <Select value={params.sortBy} onChange={handleChangeSort}>
            <option value={SortBy.createdat}>Register At</option>
            <option value={SortBy.spend}>Top Spender</option>
            <option value={SortBy.coinspend}>Top Coin Usage</option>
            <option value={SortBy.successtransaction}>Top Orders</option>
          </Select>
          <SearchInput
            placeholder="Search"
            onSubmit={({ text }) => {
              setParams(prev => ({ ...prev, page: 1, limit: 50, search: text }));
            }}
          />
        </div>
      </div>
      <Table<User> data={data?.data} columns={header} loading={isLoading || isFetching} />
      <Pagination currentPage={params.page} totalPages={Math.ceil((data?.meta.total ?? 0) / params.limit)} onPageChange={(page) => setParams(prev => ({ ...prev, page }))} />
      <ConfirmationModal isOpen={isBlockPopupOpen.open} alertType="warning" title={isBlockPopupOpen.action === "block" ? "Block Account" : "Unblock Account"} subTitle={`Are you sure want to continue ${isBlockPopupOpen.action === "block" ? "block" : "unblock"} this account?`} onClose={() => setIsBlockPopupOpen({ open: false })} onConfirm={handleBlockUnblock} />
    </ContentContainer>
  );
};

export default UserPage;
