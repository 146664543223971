import { Link, NavLink, useLocation } from "react-router-dom";
import { menuItems } from "data/sidebar-menu";
import { Button, Modal } from "react-daisyui";
import { FiLogOut } from "react-icons/fi";
import { ForwardRefRenderFunction, useCallback, useRef, useState } from "react";
import { forwardRef } from "react";
import imageLogo from "assets/images/logo.svg";
import { useAppDispatch, useAppSelector } from "store";

interface SidebarProps {
  active: boolean;
  toggleSidebar: () => void;
}

interface ModalLogoutProps {
  ref: React.DialogHTMLAttributes<HTMLDialogElement>;
  handleClose: () => void;
  handleLogout: () => void;
}

// const menuStyles = {
//   active:
//     "transition-all duration-100 p-4 bg-white cursor-pointer rounded-l-full ml-2",
//   inactive:
//     "hover:bg-slate-100 hover:bg-opacity-20 transition-all duration-100 p-4 cursor-pointer ml-2 rounded-l-full text-white",
// };

export const LogoutModal: ForwardRefRenderFunction<
  HTMLDialogElement,
  ModalLogoutProps
> = ({ handleClose, handleLogout }, ref) => {
  return (
    <div className="font-sans">
      <Modal backdrop={true} ref={ref} className="bg-white">
        <Modal.Header className="font-bold">
          Are you sure want to log out?
        </Modal.Header>
        <Modal.Body>Press ESC key or click the button below to close</Modal.Body>
        <Modal.Actions>
          <Button onClick={handleLogout} className="text-white bg-red-500">
            Yes, sure
          </Button>
          <Button onClick={handleClose}>Close</Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

const Sidebar: React.FC<SidebarProps> = ({ active }): JSX.Element => {
  const { user } = useAppSelector((state) => state.auth);
  const location = useLocation();
  const [menus, setMenus] = useState(
    menuItems.filter((item) => item.role.includes(user?.role!))
  );

  return (
    <aside
      className={`z-[999] max-h-screen h-screen overflow-auto relative top-0 bg-san-juan transition-all ${
        active ? "left-0 w-[20%]" : "-left-[20%] w-[0%]"
      }`}
    >
      <div className="pl-4 pr-2">
        <div className="w-full flex-col flex justify-center items-center gap-2 py-4">
          <img aria-label="Image Logo" src={imageLogo} width={120} />
        </div>
      </div>

      <div className="select-none mt-4 px-4 mb-20">
        <ul className="flex flex-col">
          {menus.map((item, index) => {
            const isActive = location.pathname === item.path;
            const isExpanded = item.expand ?? false;
            const hasActiveChild = item.child?.some((child) => location.pathname === child.path);
            return (
              <div key={item.path}>
                <NavLink
                  to={item.path}
                  className={`rounded-xl ${isActive || hasActiveChild ? "bg-san-juan-700" : ""}`}
                  onClick={() => {
                    if (item.child) {
                      setMenus((prev) =>
                        prev.map((menu, i) =>
                          i === index ? { ...menu, expand: !isExpanded } : menu
                        )
                      );
                    }
                  }}
                >
                  <li
                      className={`p-4 ml-2 cursor-pointer rounded-l-full text-white transition-all duration-200 
                        hover:bg-spix hover:bg-opacity-20 
                        ${isActive ? "font-bold bg-spix bg-opacity-30 shadow-md" : ""}`}
                    >
                      {item.name}
                    </li>
                </NavLink>
                {(isExpanded || hasActiveChild) && item.child && (
                  <ul className="whitespace-nowrap list-disc pl-10">
                    {item.child.map((child) => {
                      const isChildActive = location.pathname === child.path;
                      return (
                        <NavLink
                          key={child.path}
                          to={child.path}
                          className={`rounded-xl ${isChildActive ? "bg-spix" : ""}`}
                        >
                          <li
                            className={`hover:bg-spix hover:bg-opacity-20 transition-all duration-100 p-4 cursor-pointer ml-2 rounded-l-full text-white ${
                              isChildActive ? "font-bold" : ""
                            }`}
                          >
                            {child.name}
                          </li>
                        </NavLink>
                      );
                    })}
                  </ul>
                )}
              </div>
            );
          })}
        </ul>
      </div>
    </aside>
  );
};

export default Sidebar;
