import { CategoryPerformanceI, ProductPerformanceI } from "_interfaces/transaction.interfaces";
import { Order, SortBy } from "_interfaces/user.interfaces";
import ContentContainer from "components/container";
import { Columns, Table } from "components/table/table";
import moment from "moment";
import {
  FaDownload,
  FaShoppingCart,
  FaTimesCircle,
  FaCheckCircle,
  FaUser,
  FaUsers,
  FaTachometerAlt,
} from "react-icons/fa";
import { useGetAllCategoryPerformanceQuery, useGetAllProductPerformanceQuery } from "services/modules/transaction";
import { useGetDownloadSummaryQuery, useGetTransactionSummaryQuery, useGetUsersQuery, useGetUserThisMonthQuery } from "services/modules/user";

export const dashboardRouteName = "dashboard";
const DashboardPage = () => {
  const { data: userThisMonth } = useGetUserThisMonthQuery(undefined);
  const { data: users } = useGetUsersQuery({ page: 1, limit: 1, order: Order.DESC, sortBy: SortBy.createdat })
  const { data: downloadSummary } = useGetDownloadSummaryQuery(undefined);
  const { data: transactionSummary } = useGetTransactionSummaryQuery(undefined);
  const { data: categoryPerformance } = useGetAllCategoryPerformanceQuery(undefined);
  const { data: productPerformance } = useGetAllProductPerformanceQuery(undefined);

  const totalTransaction = transactionSummary?.total.reduce((prev, cur) => prev + cur.price, 0);
  const totalSuccess = transactionSummary?.success.reduce((prev, cur) => prev + cur.price, 0);
  const totalFailed = transactionSummary?.failed.reduce((prev, cur) => prev + cur.price, 0);

  const categoryHeader: Columns<CategoryPerformanceI>[] = [
    {
      fieldId: "index",
      label: "No",
    },
    {
      fieldId: "category",
      label: "Category",
      render: (item) => <span className="text-left block">{item.category}</span>,
    },
    {
      fieldId: "count",
      label: "Order",
    },
    {
      fieldId: "price",
      label: "Net Sales",
      render: (item) => `$${(item.price / 100).toLocaleString('en-US', { currency: "USD" })}`
    },
  ]

  const productHeader: Columns<ProductPerformanceI>[] = [
    {
      fieldId: "index",
      label: "No",
    },
    {
      fieldId: "name",
      label: "Category",
      render: (item) => <span className="text-left block">{item.name}</span>,
    },
    {
      fieldId: "count",
      label: "Order",
    },
    {
      fieldId: "price",
      label: "Net Sales",
      render: (item) => `$${(item.price / 100).toLocaleString('en-US', { currency: "USD" })}`
    },
  ]

  const thisMonth = moment(new Date()).format("MMMM");
  return (
    <>
      <h3 className="text-2xl font-bold bg-gradient-to-r from-[#1E3A4C] to-[#2C5A72] text-white p-3 rounded-lg shadow-md flex items-center gap-3">
        <FaTachometerAlt className="text-white text-4xl" />
        Dashboard
      </h3>
      <div className="grid grid-cols-3 gap-6 mt-6">
        <ContentContainer>
          <div className="flex flex-row items-center gap-6">
            <FaShoppingCart
              className="text-teal-600"
              size={50}
            />
            <div>
              <div className="text-1xl font-bold text-gray-800">Orders</div>
              <div className="text-lg font-bold text-teal-600">
                ${totalTransaction?.toLocaleString("en", { currency: "USD" })}{" "}
                <div className="inline-flex text-sm text-gray-500">({thisMonth})</div>
              </div>
            </div>
          </div>
        </ContentContainer>
        <ContentContainer>
          <div className="flex flex-row items-center gap-6">
            <FaCheckCircle
              className="text-teal-600"
              size={50}
            />
            <div>
              <div className="text-1xl font-bold text-gray-800">
                Success Orders
              </div>
              <div className="text-lg font-bold text-teal-600">
                ${totalSuccess?.toLocaleString("en", { currency: "USD" })}{" "}
                <div className="inline-flex text-sm text-gray-600">({thisMonth})</div>
              </div>
            </div>
          </div>
        </ContentContainer>
        <ContentContainer>
          <div className="flex flex-row items-center gap-6">
            <FaTimesCircle
              className="text-red-700"
              size={50}
            />
            <div>
              <div className="text-1xl font-bold text-gray-800">
                Failed Orders
              </div>
              <div className="text-lg font-bold text-red-700">
                ${totalFailed?.toLocaleString("en", { currency: "USD" })}{" "}
                <div className="inline-flex text-sm text-gray-600">({thisMonth})</div>
              </div>
            </div>
          </div>
        </ContentContainer>
      </div>
      <div className="grid grid-cols-3 grid-flow-row-dense items-start gap-6 mt-6">
        <div className="flex flex-col gap-6">
          <ContentContainer>
            <div className="flex flex-row items-center gap-6">
              <FaUsers
                className="text-blue-700"
                size={50}
              />
              <div>
                <div className="text-1xl font-bold text-gray-800">
                  Total Users
                </div>
                <div className="text-lg font-bold text-blue-700">{users?.meta.total ?? 0}</div>
              </div>
            </div>
          </ContentContainer>
        </div>
        <ContentContainer>
          <div className="flex flex-row items-center gap-6">
            <FaDownload
              className="text-blue-700"
              size={50}
            />
            <div>
              <div className="text-1xl font-bold text-gray-800">Downloads</div>
              <div className="text-lg font-bold text-blue-700">{downloadSummary ?? 0}</div>
            </div>
          </div>
        </ContentContainer>
        <ContentContainer>
            <div className="flex flex-row items-center gap-6">
              <FaUser
                className="text-blue-700"
                size={50}
              />
              <div>
                <div className="text-1xl font-bold text-gray-800">
                  New Users
                </div>
                <div className="text-lg font-bold text-blue-700">
                  {userThisMonth?.data ?? 0}{" "}
                  <div className="inline-flex text-sm text-gray-600">
                    {`(${thisMonth})`}
                  </div>
                </div>
              </div>
            </div>
          </ContentContainer>
        <div className="flex flex-col gap-6">
        </div>
      </div>
      <div className="grid grid-cols-2 gap-6 mt-5">
        <div className="col-span-2 overflow-y-scroll max-h-[400px]">
          <Table<CategoryPerformanceI>
            columns={categoryHeader}
            data={categoryPerformance?.categories}
          />
        </div>
        <div className="max-w-full overflow-x-scroll overflow-y-scroll max-h-[400px] col-span-3 mt-4">
          <Table<ProductPerformanceI>
            columns={productHeader}
            data={productPerformance?.products}
          />
        </div>
      </div>
    </>
  );
};

export default DashboardPage;
